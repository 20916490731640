<template>
  <v-app>
    <v-container fluid class="fill-height" id="login-bg">
      <v-row align="center" justify="center">
        <v-col cols="11" sm="10" md="10" lg="10">
          <v-card class="elevation-12">
            <v-row align="start" justify="center">
              <v-col cols="12">
                <v-img
                  :src="require('../assets/logo-hiper-200.jpg')"
                  max-height="150"
                  contain
                >
                </v-img>
              </v-col>
            </v-row>
            <v-card-title>Términos y condiciones</v-card-title>
            <v-card-text class="text-justify">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </v-card-text>
            <v-card-text class="text-justify">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </v-card-text>
            <v-card-text class="text-justify">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "mainView",

  data() {
    return {
      loading: false,
    };
  },
  created() {},
  methods: {},
};
</script>

<style>
.flat-link {
  text-decoration: none;
}
#login-bg {
  height: 100vh;
  width: 100vw;
  background-color: #0d47a1;
  background-image: linear-gradient(10deg, #81d4fa 50%, transparent 50%),
    linear-gradient(-60deg, #5e35b1 30%, transparent 30%);
}
</style>
